import React from 'react';


class Header extends React.Component {
	render() {
		return (
			<section id="footer">
				<div className="wrapper" />
			</section>
		);
	}
}


export default Header;