let _ = 'ASSETS/NOTICES/';


const SET = `${_}SET`;
const ADD = `${_}ADD`;
const REMOVE = `${_}REMOVE`;
const CLEAR = `${_}CLEAR`;


export default {
	SET,
	ADD,
	REMOVE,
	CLEAR,
};