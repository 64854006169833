let _ = 'PROJECT/WORKSPACE/';


const ADD = `${_}ADD`;
const EDIT = `${_}EDIT`;
const RESET = `${_}RESET`;
const SET_VIEW = `${_}SET_VIEW`;
const SET_CONTENT = `${_}SET_CONTENT`;


export default {
	ADD,
	EDIT,
	RESET,
	SET_VIEW,
	SET_CONTENT,
};