import Index from './views/Index';
import Duplicate from './views/Duplicate';
import Follow from './views/Follow';


export default {
	views: [
		{ route: { path: '', component: Index } },
		{ route: { path: 'duplicate/:id', component: Duplicate } },
		{ route: { path: 'follow/:id', component: Follow } },
	],
};