import { ___ } from 'classes/Translation';


export default () => ({
	label: '-',
	models: {
		id_94398: {
			label: `94398 - ${___('Lampa elewacyjna')}`,
			position: 'default',
			flash1: {
				type: 'center',
				width: 0.40,
				height: 0.50,
				position: { y: 0.147 },
			},
		},
		id_94396: {
			label: `94396 - ${___('Lampa elewacyjna z czujnikiem')}`,
			position: 'default',
			flash1: {
				type: 'center',
				width: 0.40,
				height: 0.50,
				position: { y: 0.147 },
			},
		},
		id_94331: {
			label: `94331 - ${___('Lampa elewacyjna')}`,
			position: 'default',
			flash1: {
				type: 'top',
				width: 0.40,
				height: 0.40,
				position: { y: 0.28 },
			},
			flash2: {
				type: 'bottom',
				width: 0.40,
				height: 0.40,
				position: { y: -0.28 },
			},
		},
		/* id_94330: {
			label: `94330 - ${___('Lampa elewacyjna')}`,
			position: 'default',
			flash1: {
				type: 'top',
				width: 0.40,
				height: 0.40,
				position: { y: 0.27 },
			},
			flash2: {
				type: 'bottom',
				width: 0.40,
				height: 0.40,
				position: { y: -0.27 },
			},
		},
		id_94329: {
			label: `94329 - ${___('Lampa elewacyjna')}`,
			position: 'default',
			flash1: {
				type: 'bottom',
				width: 0.40,
				height: 0.40,
				position: { y: -0.26 },
			},
		}, */
		id_94328: {
			label: `94328 - ${___('Lampa elewacyjna')}`,
			position: 'default',
			flash1: {
				type: 'top',
				width: 0.40,
				height: 0.40,
				position: { y: 0.27 },
			},
			flash2: {
				type: 'bottom',
				width: 0.40,
				height: 0.40,
				position: { y: -0.27 },
			},
		},
		id_94327: {
			label: `94327 - ${___('Lampa elewacyjna')}`,
			position: 'default',
			flash1: {
				type: 'bottom',
				width: 0.40,
				height: 0.40,
				position: { y: -0.26 },
			},
		},
		id_94383: {
			label: `94383 - ${___('Lampa elewacyjna')}`,
			position: 'bottom',
		},
		id_94382: {
			label: `94382 - ${___('Lampa elewacyjna')}`,
			position: 'bottom',
		},
		/* st01: {
			label: `ST01 - ${___('Lampa elewacyjna/inteligentna')}`,
			position: 'default',
			flash1: {
				type: 'center',
				width: 0.40,
				height: 0.50,
				position: { y: 0.08 },
			},
		},
		st02: {
			label: `ST02 - ${___('Lampa elewacyjna/inteligentna')}`,
			position: 'default',
			flash1: {
				type: 'top',
				width: 0.40,
				height: 0.50,
				position: { y: 0.22 },
			},
			flash2: {
				type: 'bottom',
				width: 0.40,
				height: 0.50,
				position: { y: -0.22 },
			},
		},
		st03: {
			label: `ST03 - ${___('Lampa elewacyjna/inteligentna')}`,
			position: 'default',
			flash1: {
				type: 'bottom',
				width: 0.40,
				height: 0.50,
				position: { y: -0.22 },
			},
		},
		st04: {
			label: `ST04 - ${___('Lampa elewacyjna/inteligentna')}`,
			position: 'default',
			flash1: {
				type: 'top',
				width: 0.40,
				height: 0.40,
				position: { y: 0.27 },
			},
			flash2: {
				type: 'bottom',
				width: 0.40,
				height: 0.40,
				position: { y: -0.27 },
			},
		},
		st05: {
			label: `ST05 - ${___('Lampa elewacyjna/inteligentna')}`,
			position: 'default',
			flash1: {
				type: 'top',
				width: 0.40,
				height: 0.40,
				position: { y: 0.27 },
			},
			flash2: {
				type: 'bottom',
				width: 0.40,
				height: 0.40,
				position: { y: -0.27 },
			},
		},
		st06: {
			label: `ST06 - ${___('Lampa elewacyjna/inteligentna')}`,
			position: 'default',
			flash1: {
				type: 'top',
				width: 0.40,
				height: 0.40,
				position: { y: 0.27 },
			},
			flash2: {
				type: 'bottom',
				width: 0.40,
				height: 0.40,
				position: { y: -0.27 },
			},
		},
		st07: {
			label: `ST07 - ${___('Lampa elewacyjna/inteligentna')}`,
			position: 'default',
			flash1: {
				type: 'top',
				width: 0.40,
				height: 0.40,
				position: { y: 0.27 },
			},
			flash2: {
				type: 'bottom',
				width: 0.40,
				height: 0.40,
				position: { y: -0.27 },
			},
		},
		st08: {
			label: `ST08 - ${___('Lampa elewacyjna/inteligentna')}`,
			position: 'default',
			flash1: {
				type: 'top',
				width: 0.40,
				height: 0.40,
				position: { y: 0.27 },
			},
			flash2: {
				type: 'bottom',
				width: 0.40,
				height: 0.40,
				position: { y: -0.27 },
			},
		},
		st09: {
			label: `ST09 - ${___('Lampa elewacyjna/inteligentna')}`,
			position: 'default',
			flash1: {
				type: 'top',
				width: 0.40,
				height: 0.40,
				position: { y: 0.27 },
			},
			flash2: {
				type: 'bottom',
				width: 0.40,
				height: 0.40,
				position: { y: -0.27 },
			},
		}, */
		solar: {
			label: `95388 - ${___('Lampa solarna')}`,
			position: 'default',
			flash1: {
				type: 'center',
				width: 0.50,
				height: 0.35,
				position: { y: -0.03 },
			},
			text: true,
		},
	},
});