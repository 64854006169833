import { ___ } from 'classes/Translation';

import colors from './colors';


export default () => ({
	label: '-',
	models: {
		lb_l_h20: {
			model: 'lb_l_h20',
			label: 'LB_L_H20',
			description: `${___('Lampa LED BLOCK')} LIGHT`,
			colors: colors().default,
			targetReplacement: ['lb_l_h28'],
			size: {
				width: 0.504,
				height: 0.05,
				depth: 0.20,
			},
			light: true,
			blink: false,
		},
		lb_f_h20: {
			model: 'lb_l_h20',
			label: 'LB_F_H20',
			description: `${___('Lampa LED BLOCK')} FLASH`,
			colors: colors().default,
			targetReplacement: ['lb_f_h28'],
			size: {
				width: 0.504,
				height: 0.05,
				depth: 0.20,
			},
			light: false,
			blink: true,
		},
		lb_m_h20: {
			model: 'lb_l_h20',
			label: 'LB_M_H20',
			description: `${___('Lampa LED BLOCK')} MULTI`,
			colors: colors().default,
			targetReplacement: ['lb_m_h28'],
			size: {
				width: 0.504,
				height: 0.05,
				depth: 0.20,
			},
			light: true,
			blink: true,
		},

		lba_l_h20: {
			model: 'lba_l_h20',
			label: 'LBA_L_H20',
			description: `${___('Lampa LED BLOCK')} LIGHT`,
			colors: colors().address,
			targetReplacement: ['lba_l_h28'],
			size: {
				width: 0.504,
				height: 0.05,
				depth: 0.20,
			},
			light: true,
			blink: false,
		},
		lba_m_h20: {
			model: 'lba_l_h20',
			label: 'LBA_M_H20',
			description: `${___('Lampa LED BLOCK')} MULTI`,
			colors: colors().address,
			targetReplacement: ['lba_m_h28'],
			size: {
				width: 0.504,
				height: 0.05,
				depth: 0.20,
			},
			light: true,
			blink: true,
		},

		lb_l_h28: {
			model: 'lb_l_h28',
			label: 'LB_L_H28',
			description: `${___('Lampa LED BLOCK')} LIGHT`,
			colors: colors().default,
			targetReplacement: ['lb_l_h20'],
			size: {
				width: 0.504,
				height: 0.05,
				depth: 0.28,
			},
			light: true,
			blink: false,
		},
		lb_f_h28: {
			model: 'lb_l_h28',
			label: 'LB_F_H28',
			description: `${___('Lampa LED BLOCK')} FLASH`,
			colors: colors().default,
			targetReplacement: ['lb_f_h20'],
			size: {
				width: 0.504,
				height: 0.05,
				depth: 0.28,
			},
			light: false,
			blink: true,
		},
		lb_m_h28: {
			model: 'lb_l_h28',
			label: 'LB_M_H28',
			description: `${___('Lampa LED BLOCK')} MULTI`,
			colors: colors().default,
			targetReplacement: ['lb_m_h20'],
			size: {
				width: 0.504,
				height: 0.05,
				depth: 0.28,
			},
			light: true,
			blink: true,
		},

		lba_l_h28: {
			model: 'lba_l_h28',
			label: 'LBA_L_H28',
			description: `${___('Lampa LED BLOCK')} LIGHT`,
			colors: colors().address,
			targetReplacement: ['lba_l_h20'],
			size: {
				width: 0.504,
				height: 0.05,
				depth: 0.28,
			},
			light: true,
			blink: false,
		},
		lba_m_h28: {
			model: 'lba_l_h28',
			label: 'LBA_M_H28',
			description: `${___('Lampa LED BLOCK')} MULTI`,
			colors: colors().address,
			targetReplacement: ['lba_m_h20'],
			size: {
				width: 0.504,
				height: 0.05,
				depth: 0.28,
			},
			light: true,
			blink: true,
		},

		lb_l_rm19: {
			model: 'lb_l_rm19',
			label: 'LB_L_RM19',
			description: `${___('Lampa LED BLOCK')} LIGHT`,
			colors: colors().default,
			targetReplacement: false,
			size: {
				width: 0.756,
				height: 0.07,
				depth: 0.195,
			},
			light: true,
			blink: false,
		},
		lb_f_rm19: {
			model: 'lb_l_rm19',
			label: 'LB_F_RM19',
			description: `${___('Lampa LED BLOCK')} FLASH`,
			colors: colors().default,
			targetReplacement: false,
			size: {
				width: 0.756,
				height: 0.07,
				depth: 0.195,
			},
			light: false,
			blink: true,
		},
		lb_m_rm19: {
			model: 'lb_l_rm19',
			label: 'LB_M_RM19',
			description: `${___('Lampa LED BLOCK')} MULTI`,
			colors: colors().default,
			targetReplacement: false,
			size: {
				width: 0.756,
				height: 0.07,
				depth: 0.195,
			},
			light: true,
			blink: true,
		},

		lba_l_rm19: {
			model: 'lba_l_rm19',
			label: 'LBA_L_RM19',
			description: `${___('Lampa LED BLOCK')} LIGHT`,
			colors: colors().address,
			targetReplacement: false,
			size: {
				width: 0.756,
				height: 0.07,
				depth: 0.195,
			},
			light: true,
			blink: false,
		},
		lba_m_rm19: {
			model: 'lba_l_rm19',
			label: 'LBA_M_RM19',
			description: `${___('Lampa LED BLOCK')} MULTI`,
			colors: colors().address,
			targetReplacement: false,
			size: {
				width: 0.756,
				height: 0.07,
				depth: 0.195,
			},
			light: true,
			blink: true,
		},

		lb_l_gl22: {
			model: 'lb_l_gl22',
			label: 'LB_L_GL22',
			description: `${___('Lampa LED BLOCK')} LIGHT`,
			colors: colors().address,
			targetReplacement: false,
			size: {
				width: 0.38,
				height: 0.06,
				depth: 0.22,
			},
			light: true,
			blink: false,
		},
		lb_m_gl22: {
			model: 'lb_l_gl22',
			label: 'LB_M_GL22',
			description: `${___('Lampa LED BLOCK')} MULTI`,
			colors: colors().address,
			targetReplacement: false,
			size: {
				width: 0.38,
				height: 0.06,
				depth: 0.22,
			},
			light: true,
			blink: true,
		},

		column_m_h20: {
			model: 'column_m_h20',
			label: 'Słupek multimedialny',
			description: `${___('LED BLOCK')} MULTI`,
			colors: colors().address,
			targetReplacement: ['column_m_h28'],
			size: {
				width: 0.504,
				height: 0.05,
				depth: 0.20,
			},
			offset: 0.60,
			light: true,
			blink: true,
			mailbox: 'smvd_k',
			mailboxBack: 'smz',
		},
		column_m_h28: {
			model: 'column_m_h28',
			label: 'Słupek multimedialny',
			description: `${___('LED BLOCK')} MULTI`,
			colors: colors().address,
			targetReplacement: ['column_m_h20'],
			size: {
				width: 0.504,
				height: 0.05,
				depth: 0.28,
			},
			offset: 0.60,
			light: true,
			blink: true,
			mailbox: 'sdvd_k',
			mailboxBack: 'sdz',
		},
	},
});