import React from 'react';

import Template from 'templates/MainTemplate';


class View extends React.Component {
	render() {
		return (
			<Template>
				<h1>404</h1>
			</Template>
		);
	}
}


export default View;