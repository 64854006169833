import { ___ } from 'classes/Translation';

import colors from './colors';


export default () => ({
	label: '-',
	models: {
		sdz: {
			label: 'SDZ',
			description: ___('Skrzynka duża zwykła'),
			colors: colors().default,
			target: [0.26, 0.41],
			targetReplacement: ['smz', false],
			front: {
				model: 'smz',
				width: 0.285,
				height: 0.145,
				depth: 0.005,
				position: { y: 0 },
			},
			back: {
				model: 'sdz',
				width: 0.26,
				height: 0.11,
				depth: 0.002,
				position: { y: -0.04 - 0.06 },
			},
			additionals: {
				frame: { model: 'big', auto: true },
				roof: { model: 'universal', auto: false },
			},
		},
		sdz_2sz: {
			label: 'SDZ-2SZ',
			description: ___('Podwójna skrzynka duża zwykła z dwoma wrzutami'),
			colors: colors().inox,
			target: [0.26, 0.41],
			targetReplacement: [false, false],
			front: {
				model: 'sdz_2sz',
				width: 0.285,
				height: 0.244,
				depth: 0.005,
				position: { y: 0 },
			},
			back: {
				model: 'sdz_2sz',
				width: 0.26,
				height: 0.22,
				depth: 0.002,
				position: { y: -0.04 - 0.06 },
			},
			additionals: {
				frame: { model: 'big_2sz', auto: false },
				roof: { model: 'universal', auto: false },
			},
		},
		smz: {
			label: 'SMZ',
			description: ___('Skrzynka mała zwykła'),
			colors: colors().default,
			target: [0.19, 0.255],
			targetReplacement: [false, 'sdz'],
			front: {
				model: 'smz',
				width: 0.285,
				height: 0.145,
				depth: 0.005,
				position: { y: 0 },
			},
			back: {
				model: 'smz',
				width: 0.251,
				height: 0.363,
				depth: 0.002,
				position: { y: -0.02 },
			},
			additionals: {
				frame: { model: 'small', auto: true },
				roof: { model: 'universal', auto: false },
			},
		},
		sdg: {
			label: 'SDG',
			description: ___('Skrzynka duża z dzwonkiem'),
			path: 'sdz',
			colors: colors().default,
			target: [0.26, 0.41],
			targetReplacement: ['smg', false],
			front: {
				model: 'smg',
				width: 0.285,
				height: 0.158,
				depth: 0.005,
				position: { y: 0 },
			},
			back: {
				model: 'sdz',
				width: 0.26,
				height: 0.11,
				depth: 0.002,
				position: { y: -0.04 - 0.06 },
			},
			additionals: {
				frame: { model: 'big', auto: true },
				roof: { model: 'universal', auto: false },
			},
		},
		smg: {
			label: 'SMG',
			description: ___('Skrzynka mała z dzwonkiem'),
			path: 'smz',
			colors: colors().default,
			target: [0.19, 0.255],
			targetReplacement: [false, 'sdg'],
			front: {
				model: 'smg',
				width: 0.285,
				height: 0.158,
				depth: 0.005,
				position: { y: 0 },
			},
			back: {
				model: 'smz',
				width: 0.251,
				height: 0.363,
				depth: 0.002,
				position: { y: -0.02 },
			},
			additionals: {
				frame: { model: 'small', auto: true },
				roof: { model: 'universal', auto: false },
			},
		},
		sdda: {
			label: 'SDDA',
			description: ___('Skrzynka duża z domofonem analogowym'),
			path: 'sdz',
			colors: colors().default,
			target: [0.26, 0.41],
			targetReplacement: ['smda', false],
			front: {
				model: 'smda',
				width: 0.285,
				height: 0.205,
				depth: 0.005,
				position: { y: 0 },
			},
			back: {
				model: 'sdz',
				width: 0.26,
				height: 0.11,
				depth: 0.002,
				position: { y: -0.04 - 0.06 },
			},
			additionals: {
				frame: { model: 'big', auto: true },
				roof: { model: 'universal', auto: false },
			},
		},
		smda: {
			label: 'SMDA',
			description: ___('Skrzynka mała z domofonem analogowym'),
			path: 'smz',
			colors: colors().default,
			target: [0.19, 0.255],
			targetReplacement: [false, 'sdda'],
			front: {
				model: 'smda',
				width: 0.285,
				height: 0.205,
				depth: 0.005,
				position: { y: 0 },
			},
			back: {
				model: 'smz',
				width: 0.251,
				height: 0.363,
				depth: 0.002,
				position: { y: -0.02 },
			},
			additionals: {
				frame: { model: 'small', auto: true },
				roof: { model: 'universal', auto: false },
			},
		},
		sdvd_r: {
			label: `SDVD (${___('czytnik RFID')})`,
			description: ___('Skrzynka duża z videofonem i czytnikiem RFID'),
			path: 'sdz',
			colors: colors().default,
			target: [0.26, 0.41],
			targetReplacement: ['smvd_r', false],
			front: {
				model: 'sdvd_r',
				width: 0.285,
				height: 0.332,
				depth: 0.005,
				position: { y: 0 },
			},
			back: {
				model: 'sdz',
				width: 0.26,
				height: 0.11,
				depth: 0.002,
				position: { y: -0.04 - 0.27 },
			},
			additionals: {
				frame: { model: 'big', auto: true },
				roof: { model: 'universal', auto: false },
			},
		},
		smvd_r: {
			label: `SMVD (${___('czytnik RFID')})`,
			description: ___('Skrzynka mała z videofonem i czytnikiem RFID'),
			path: 'smz',
			colors: colors().default,
			target: [0.19, 0.255],
			targetReplacement: [false, 'sdvd_r'],
			front: {
				model: 'smvd_r',
				width: 0.285,
				height: 0.385,
				depth: 0.005,
				position: { y: 0 },
			},
			back: {
				model: 'smz',
				width: 0.251, // 0.265
				height: 0.363, // 0.36
				depth: 0.002,
				position: { y: -0.02 },
			},
			additionals: {
				frame: { model: 'small', auto: true },
				roof: { model: 'universal', auto: false },
			},
		},
		sdvd_k: {
			label: `SDVD (${___('klawiatura')})`,
			description: ___('Skrzynka duża z videofonem i klawiaturą dotykową'),
			path: 'sdz',
			colors: colors().default,
			target: [0.26, 0.41],
			targetReplacement: ['smvd_k', false],
			front: {
				model: 'sdvd_k',
				width: 0.285,
				height: 0.332,
				depth: 0.005,
				position: { y: 0 },
			},
			back: {
				model: 'sdz',
				width: 0.26,
				height: 0.11,
				depth: 0.002,
				position: { y: -0.04 - 0.27 },
			},
			additionals: {
				frame: { model: 'big', auto: true },
				roof: { model: 'universal', auto: false },
			},
		},
		smvd_k: {
			label: `SMVD (${___('klawiatura')})`,
			description: ___('Skrzynka mała z videofonem i klawiaturą dotykową'),
			path: 'smz',
			colors: colors().default,
			target: [0.19, 0.255],
			targetReplacement: [false, 'sdvd_k'],
			front: {
				model: 'smvd_k',
				width: 0.285,
				height: 0.385,
				depth: 0.005,
				position: { y: 0 },
			},
			back: {
				model: 'smz',
				width: 0.251, // 0.265
				height: 0.363, // 0.36
				depth: 0.002,
				position: { y: -0.02 },
			},
			additionals: {
				frame: { model: 'small', auto: true },
				roof: { model: 'universal', auto: false },
			},
		},
		sdzp: {
			label: 'SDZP',
			description: ___('Skrzynka duża zwykła personalizowana'),
			path: 'sdz',
			colors: colors().inox,
			target: [0.26, 0.41],
			targetReplacement: ['smzp', false],
			front: {
				model: 'sdzp',
				width: 0.285,
				height: 0.332,
				depth: 0.005,
				position: { y: 0 },
			},
			back: {
				model: 'sdz',
				width: 0.26,
				height: 0.11,
				depth: 0.002,
				position: { y: -0.04 - 0.25 },
			},
			additionals: {
				frame: { model: 'big', auto: true },
				roof: { model: 'universal', auto: false },
			},
		},
		smzp: {
			label: 'SMZP',
			description: ___('Skrzynka mała zwykła personalizowana'),
			path: 'smz',
			colors: colors().inox,
			target: [0.19, 0.255],
			targetReplacement: [false, 'sdzp'],
			front: {
				model: 'smzp',
				width: 0.285,
				height: 0.385,
				depth: 0.005,
				position: { y: 0 },
			},
			back: {
				model: 'smz',
				width: 0.251, // 0.265
				height: 0.363, // 0.36
				depth: 0.002,
				position: { y: -0.02 },
			},
			additionals: {
				frame: { model: 'small', auto: true },
				roof: { model: 'universal', auto: false },
			},
		},
		sdgp: {
			label: 'SDGP',
			description: ___('Skrzynka duża z dzwonkiem personalizowana'),
			path: 'sdz',
			colors: colors().inox,
			target: [0.26, 0.41],
			targetReplacement: ['smgp', false],
			front: {
				model: 'sdgp',
				width: 0.285,
				height: 0.332,
				depth: 0.005,
				position: { y: 0 },
			},
			back: {
				model: 'sdz',
				width: 0.26,
				height: 0.11,
				depth: 0.002,
				position: { y: -0.04 - 0.25 },
			},
			additionals: {
				frame: { model: 'big', auto: true },
				roof: { model: 'universal', auto: false },
			},
		},
		smgp: {
			label: 'SMGP',
			description: ___('Skrzynka mała z dzwonkiem personalizowana'),
			path: 'smz',
			colors: colors().inox,
			target: [0.19, 0.255],
			targetReplacement: [false, 'sdgp'],
			front: {
				model: 'smgp',
				width: 0.285,
				height: 0.385,
				depth: 0.005,
				position: { y: 0 },
			},
			back: {
				model: 'smz',
				width: 0.251, // 0.265
				height: 0.363, // 0.36
				depth: 0.002,
				position: { y: -0.02 },
			},
			additionals: {
				frame: { model: 'small', auto: true },
				roof: { model: 'universal', auto: false },
			},
		},
		sddap: {
			label: 'SDDAP',
			description: ___('Skrzynka duża z domofonem analogowym personalizowana'),
			path: 'sdz',
			colors: colors().inox,
			target: [0.26, 0.41],
			targetReplacement: ['smdap', false],
			front: {
				model: 'sddap',
				width: 0.285,
				height: 0.332,
				depth: 0.005,
				position: { y: 0 },
			},
			back: {
				model: 'sdz',
				width: 0.26,
				height: 0.11,
				depth: 0.002,
				position: { y: -0.04 - 0.25 },
			},
			additionals: {
				frame: { model: 'big', auto: true },
				roof: { model: 'universal', auto: false },
			},
		},
		smdap: {
			label: 'SMDAP',
			description: ___('Skrzynka mała z domofonem analogowym personalizowana'),
			path: 'smz',
			colors: colors().inox,
			target: [0.19, 0.255],
			targetReplacement: [false, 'sddap'],
			front: {
				model: 'smdap',
				width: 0.285,
				height: 0.385,
				depth: 0.005,
				position: { y: 0 },
			},
			back: {
				model: 'smz',
				width: 0.251, // 0.265
				height: 0.363, // 0.36
				depth: 0.002,
				position: { y: -0.02 },
			},
			additionals: {
				frame: { model: 'small', auto: true },
				roof: { model: 'universal', auto: false },
			},
		},
	},
});