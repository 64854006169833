let _ = 'ASSETS/POPUP/';


const SHOW = `${_}SHOW`;
const HIDE = `${_}HIDE`;


export default {
	SHOW,
	HIDE,
};