let _ = 'PROJECT/PANEL/';


const SET = `${_}SET`;
const RESET = `${_}RESET`;


export default {
	SET,
	RESET,
};