import 'es6-promise-promise';

import React from 'react';
import ReactDOM from 'react-dom';

import Root from 'Root';
import 'style.scss';


ReactDOM.render(
	<Root />,
	document.getElementById('app'),
);