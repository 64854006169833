import React from 'react';


class BoxLoading extends React.Component {
	render() {
		return (
			<div className="box-loading">
				<div />
			</div>
		);
	}
}


export default BoxLoading;