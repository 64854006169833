import { ___ } from 'classes/Translation';


export default () => ({
	default: {
		inox: { label: ___('Inox'), path: '/assets/img/mailboxes/inox.jpg' },
		ral7016: { label: 'RAL 7016', color: '3b3e3f' },
		ral7021: { label: 'RAL 7021', color: '6a797e' },
		ral8019: { label: 'RAL 8019', color: '42322f' },
		ral9005: { label: 'RAL 9005', color: '222222' },
	},
	address: {
		inox: { label: ___('Inox'), path: '/assets/img/mailboxes/inox.jpg' },
		ral7016: { label: 'RAL 7016', color: '3b3e3f' },
		ral7021: { label: 'RAL 7021', color: '6a797e' },
		ral9005: { label: 'RAL 9005', color: '222222' },
	},
});