import Notices from './assets/Notices';
import Dialog from './assets/Dialog';
import Popup from './assets/Popup';

import Main from './Main';
import Users from './Users';
import Project from './Project';


export default [
	Notices,
	Dialog,
	Popup,

	Main,
	Users,
	Project,
];