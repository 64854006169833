import data from './data';


export default () => ({
	label: 'COMO',
	colors: data().colors.default,
	default: {
		variant: 'como',
		color: 'ral7016',
	},
	pattern: {
		size: {
			maxHeight: 2.20,
		},
		customizable: false,
		type: 'horizontal',
		slats: {
			size: 0.02,
			depth: 0.075,
			space: [0.0098, 0.037],
		},
		panels: {
			mode: 'simple',
			variant: {
				size: 0.08,
				depth: 0.02,
				structure: {
					size: 0.08,
					space: null,
					angle: 0,
				},
			},
		},
		joiners: {
			interval: 2.80,
			width: 0.08,
			depth: 0.08,
		},
	},
	variants: {
		como: {
			label: 'Como',
			panels: {
				variant: {
					structure: {
						space: 0.059,
					},
				},
			},
		},
	},
});