import { ___ } from 'classes/Translation';

import colors from './colors';


export default () => ({
	label: '-',
	models: {
		roof: {
			model: 'roof',
			label: ___('Zadaszenie'),
			colors: colors().default,
			size: {
				width: 1.512,
				depth: 0.28,
			},
			additionals: {
				control: {
					model: 'control_l_h28',
				},
			},
			offset: 0.80,
			mailbox: 'sdvd_k',
			mailboxBack: 'sdz',
		},
	},
});