let _ = 'ASSETS/DIALOG/';


const SHOW = `${_}SHOW`;
const HIDE = `${_}HIDE`;


export default {
	SHOW,
	HIDE,
};